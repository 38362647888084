import React, { FC } from 'react';
import { GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { Spinner } from '../Spinners';
import { getEnvVar, useStreetViewAvailable } from './useStreetViewAvailable';

const LIBRARIES = ['places'];

interface StreetViewMapProps {
    lat: number;
    lng: number;
    notAvailableText?: string;
    title?: string;
    streetViewContainerHeight?: number;
}

export const StreetViewMap: FC<StreetViewMapProps> = ({
    lat,
    lng,
    notAvailableText = 'Not Available',
    title = 'Street View',
    streetViewContainerHeight = 138,
}) => {
    const { isAvailable } = useStreetViewAvailable({ lat, lng });

    const center = {
        lat,
        lng,
    };

    return (
        <>
            <div className="mb-1 text-xs text-navy-70">{title}</div>
            {!isAvailable && (
                <div className="flex h-8 w-full items-center justify-start text-xs italic">
                    {notAvailableText}
                </div>
            )}
            {isAvailable && (
                <div
                    className="overflow-hidden"
                    style={{
                        height: streetViewContainerHeight,
                    }}
                >
                    <div
                        className="w-full"
                        style={{
                            height: streetViewContainerHeight + 22,
                        }}
                    >
                        <LoadScript
                            googleMapsApiKey={getEnvVar()}
                            libraries={LIBRARIES as any}
                            loadingElement={<Spinner size={30} />}
                        >
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '100%',
                                    width: '100%',
                                }}
                                center={center}
                                zoom={20}
                                options={{
                                    streetViewControl: false,
                                }}
                            >
                                <StreetViewPanorama
                                    options={{
                                        visible: true,
                                        position: center,
                                        disableDefaultUI: true,
                                        zoomControl: false,
                                        fullscreenControl: true,
                                        motionTrackingControl: false,
                                        panControl: false,
                                        enableCloseButton: false,
                                    }}
                                />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            )}
        </>
    );
};
