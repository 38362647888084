import { X } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Text from 'andromeda/text';

const SelectedItemBox = React.forwardRef(
    (
        { children, onRemove }: { onRemove: () => void; children: string | JSX.Element },
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const [state, setState] = useState<'default' | 'x-hover' | 'hover'>('default');
        const background = useMemo(() => {
            if (state === 'x-hover')
                return 'linear-gradient(91deg, rgba(70, 108, 241, 0.80) 0%, rgba(33, 78, 236, 0.80) 100%), #81859B';
            if (state === 'hover')
                return 'background: linear-gradient(91deg, #466CF1 0%, #214EEC 100%)';
            return 'linear-gradient(91deg, rgba(70, 108, 241, 0.80) 0%, rgba(33, 78, 236, 0.80) 100%)';
        }, [state]);
        return (
            <div
                className="group flex items-center gap-1 rounded py-0.5 pl-2 pr-1"
                style={{ background }}
                onMouseLeave={() => setState('default')}
                onMouseEnter={() => setState('hover')}
                ref={ref}
            >
                <Text type="12Mid" className="text-white hover:!text-white">
                    {children}
                </Text>
                <button
                    onMouseEnter={() => setState('x-hover')}
                    onMouseLeave={() => setState('hover')}
                    type="button"
                    onClick={onRemove}
                    aria-label="remove selected item"
                    className="rounded p-1 text-white hover:bg-shadow-200 hover:!text-white"
                >
                    <X size={12} />
                </button>
            </div>
        );
    },
);

export default SelectedItemBox;
