import { useQuery } from '@tanstack/react-query';

export function getEnvVar() {
    // Check if running in Storybook
    if (process.env.STORYBOOK_GOOGLE_API_KEY) {
        return process.env[`STORYBOOK_GOOGLE_API_KEY`];
    }
    // Default to NEXT_PUBLIC_ prefix for Next.js applications
    return process.env[`NEXT_PUBLIC_GOOGLE_API_KEY`];
}
const fetchStreetViewMetadata = async (latitude, longitude) => {
    const apiUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?key=${getEnvVar()}&location=${latitude},${longitude}`;

    const res = await fetch(apiUrl);
    return res.json();
};

export const useStreetViewAvailable = ({ lat, lng }) => {
    const { data, error } = useQuery({
        queryKey: ['streetViewMetadata', lat, lng],
        queryFn: () => fetchStreetViewMetadata(lat, lng),
    });
    return {
        isAvailable: data?.status !== 'ZERO_RESULTS',
    };
};
