import { Radio as MantineRadio, RadioProps } from '@mantine/core';
import React, { FC } from 'react';
import cx from 'classnames';
import colors from '../constants/colors';

export const Radio: FC<RadioProps> = props => {
    const { checked, disabled } = props;
    return (
        <MantineRadio
            classNames={{
                label: cx(
                    'text-xs leading-5 font-medium !text-navy-50 data-[disabled=true]:!text-navy-30',
                    {
                        '!text-navy-70': checked,
                        'group-hover:!text-navy-70': !disabled,
                    },
                ),
                radio: cx('border-navy-30 disabled:!bg-navy-10 disabled:!border-navy-30', {
                    '!bg-[#fff]': !checked,
                    'border-none': checked,
                    'group-hover:!border-navy-50': !disabled,
                }),
                body: 'group',
            }}
            color={colors.arenaBlue}
            {...props}
        />
    );
};
