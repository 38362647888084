import { TableState } from '@tanstack/react-table';
import { useState } from 'react';

export const useTableState = (initialState?: Partial<TableState>) => {
    const [tableState, setTableState] = useState<Partial<TableState>>(initialState);

    return {
        tableState,
        setTableState,
    };
};
