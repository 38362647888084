import React, { FC } from 'react';
import { ColorSquare } from '../colorShapes/colorSquare';
import { Dataset } from '../types/timeSeriesChart';

interface LegendsProps {
    datasets: Dataset[];
}

export const Legends: FC<LegendsProps> = ({ datasets }) => (
    <div className="flex justify-center gap-4">
        {datasets.map(({ name, color }) => (
            <div key={name} className="flex items-center gap-2">
                <ColorSquare color={color} size={12} />
                <span className="text-xs text-navy-30">{name}</span>
            </div>
        ))}
    </div>
);
