import React from 'react';

const STROKE_WIDTH = 1;

interface ColorCircleProps {
    color: string;
    radius: number;
    variant?: 'normal' | 'outline';
    className?: string;
}

export const ColorCircle = ({ color, radius, variant = 'normal', className }: ColorCircleProps) => {
    const fillColor = variant === 'outline' ? 'none' : color;

    return (
        <svg
            className={className}
            width={radius * 2 + STROKE_WIDTH * 2}
            height={radius * 2 + STROKE_WIDTH * 2}
            viewBox={`0 0 ${radius * 2 + STROKE_WIDTH * 2} ${radius * 2 + STROKE_WIDTH * 2}`}
        >
            <circle
                cx={radius + STROKE_WIDTH}
                cy={radius + STROKE_WIDTH}
                r={radius - STROKE_WIDTH / 2}
                fill={fillColor}
                stroke={color}
                strokeWidth={STROKE_WIDTH}
            />
        </svg>
    );
};
