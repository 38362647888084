import React, { HTMLAttributes, ThHTMLAttributes } from 'react';
import cx from 'classnames';

export const Th: React.FC<ThHTMLAttributes<HTMLTableCellElement>> = ({ className, ...props }) => (
    <th
        {...props}
        className={cx('p-3 font-manrope text-xs font-semibold text-primary-50', className)}
    />
);

export const Td: React.FC<ThHTMLAttributes<HTMLTableCellElement>> = ({ className, ...props }) => (
    <td {...props} className={cx('px-3 py-2 font-manrope text-xs text-primary-90', className)} />
);

export const Tr: React.FC<HTMLAttributes<HTMLTableRowElement>> = ({ className, ...props }) => (
    <tr {...props} className={cx('border-b border-primary-30 last:!border-b-0', className)} />
);
