import Color from 'color';
import { useMemo } from 'react';

interface ColorSquareProps {
    color: string;
    className?: string;
    variant?: 'outline' | 'dashed' | 'border' | 'normal';
    size?: number;
}

export const ColorSquare = ({
    color,
    className,
    variant = 'normal',
    size = 10,
}: ColorSquareProps) => {
    const border = useMemo(() => {
        if (variant === 'outline' || variant === 'border') return `1px solid ${color}`;
        if (variant === 'dashed') return `1px dashed ${color}`;
        return 'none';
    }, [color, variant]);
    const innerColor = useMemo(() => {
        if (variant === 'outline' || variant === 'dashed') return 'transparent';
        if (variant === 'border') return Color(color).alpha(0.5).rgb().string();
        return color;
    }, [color, variant]);
    return (
        <div
            className={`rounded-sm ${className}`}
            style={{
                backgroundColor: innerColor,
                border,
                height: `${size}px`,
                width: `${size}px`,
            }}
        />
    );
};
