import React, { useEffect } from 'react';
import cx from 'classnames';

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Row,
    TableOptions,
    TableState,
    useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';
import { CaretDown } from 'phosphor-react';
import NoDataCard from 'andromeda/noDataCard';
import { Td, Th, Tr } from './Subcomponents';

interface TableProps<T> {
    columns: ColumnDef<T>[];
    data: T[];
    tableState?: Partial<TableState>;
    onTableStateChange?: (state: TableState) => void;
    className?: string;
    sortable?: boolean;
    stickyHeader?: boolean;
    noDataMessage?: string | React.ReactNode;
    tableOptions?: Omit<
        TableOptions<T>,
        | 'data'
        | 'columns'
        | 'getCoreRowModel'
        | 'getPaginationRowModel'
        | 'getSortedRowModel'
        | 'getRowStyles'
    >;
    onClickRow?: (row: Row<T>) => void;
    onHoverRow?: (row: Row<T>) => void;
    headClassName?: string;
    headItemClassName?: string;
    rowClassName?: ((row: Row<T>) => string) | string;
    colClassName?: string;
    activePage?: number;
    additionalTopComponent?: React.ReactNode;
    disableHeaderMargin?: boolean;
    onSortFn?: (status) => void;
    resetSorting?: 'reset' | 'desc' | 'asc';
    pageSize?: number;
}

export const Table = <T,>({
    columns,
    data,
    tableState,
    onTableStateChange,
    className,
    sortable,
    stickyHeader,
    noDataMessage,
    tableOptions = {},
    onClickRow,
    headClassName,
    headItemClassName,
    rowClassName,
    colClassName,
    onHoverRow,
    activePage,
    additionalTopComponent,
    disableHeaderMargin,
    onSortFn,
    resetSorting,
    pageSize,
}: TableProps<T>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: pageSize || 100,
            },
        },
        ...tableOptions,
    });

    useEffect(() => {
        if (activePage) table.setPageIndex(activePage - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage]);

    useEffect(() => {
        if (resetSorting === 'reset') {
            table.resetSorting();
            if (onSortFn) onSortFn(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSorting]);

    if (tableState || onTableStateChange) {
        table.setOptions(prev => ({
            ...prev,
            state: _.defaultsDeep(tableState, table.initialState),
            onStateChange: onTableStateChange,
        }));
    }

    const footers = table
        .getFooterGroups()
        .map(group => group.headers.map(header => header.column.columnDef.footer))
        .flat()
        .filter(Boolean);

    return (
        <table className={cx('w-full text-left relative', className)}>
            <thead
                className={cx(headClassName, {
                    'gradient-bg-hex sticky top-0 z-10 ': stickyHeader,
                })}
            >
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className={headItemClassName}>
                        {headerGroup.headers.map((header, idx) => (
                            <Th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={table.options.meta?.['getHeaderStyles']?.(header, idx)}
                                className={cx(
                                    table.options.meta?.['getHeaderClassName']?.(header, idx),
                                    {
                                        'sticky top-0 z-10': stickyHeader,
                                    },
                                )}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          <button
                                              type="button"
                                              className={cx(
                                                  'flex items-center gap-1 transition-colors duration-75',
                                                  {
                                                      'pointer-events-none': !sortable,
                                                      'hover:text-primary-30': sortable,
                                                      'm-auto': idx !== 0 && !disableHeaderMargin,
                                                  },
                                              )}
                                              onClick={() => {
                                                  if (header.column.getCanSort()) {
                                                      header.column.toggleSorting(
                                                          header.column.getIsSorted() === 'asc',
                                                      );
                                                      onSortFn(header.column.getIsSorted());
                                                  }
                                              }}
                                          >
                                              {header.column.columnDef.header as React.ReactNode}
                                              {sortable && header.column.getCanSort() && (
                                                  <CaretDown
                                                      size={14}
                                                      className={cx('transition-transform', {
                                                          'rotate-180':
                                                              header.column.getIsSorted() === 'asc',
                                                          hidden: !header.column.getIsSorted(),
                                                      })}
                                                  />
                                              )}
                                          </button>,
                                          header.getContext(),
                                      )}
                            </Th>
                        ))}
                    </tr>
                ))}
                {additionalTopComponent && additionalTopComponent}
            </thead>
            <tbody id="andromeda-table-body">
                {!data || data.length === 0 ? (
                    <div className="absolute mx-auto mt-32 flex w-full justify-center">
                        {typeof noDataMessage === 'string' ? (
                            <NoDataCard>{noDataMessage || 'No data was found'}</NoDataCard>
                        ) : (
                            noDataMessage
                        )}
                    </div>
                ) : (
                    table.getRowModel().rows.map(row => (
                        <Tr
                            id={row.id}
                            key={row.id}
                            onClick={e => {
                                e.stopPropagation();
                                onClickRow?.(row);
                            }}
                            onKeyDown={e => {
                                e.stopPropagation();
                                onClickRow?.(row);
                            }}
                            aria-hidden
                            className={
                                typeof rowClassName === 'string'
                                    ? rowClassName
                                    : rowClassName?.(row)
                            }
                            style={table.options.meta?.['getRowStyles']?.(row)}
                            onMouseOver={() => onHoverRow?.(row)}
                        >
                            {row.getVisibleCells().map((cell, index) => (
                                <Td
                                    key={cell.id}
                                    id={cell.id}
                                    style={table.options.meta?.['getCellStyles']?.(cell, index)}
                                    className={cx(
                                        colClassName,
                                        table.options.meta?.['getCellClassName']?.(cell, index),
                                    )}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            ))}
                        </Tr>
                    ))
                )}
            </tbody>
            {footers.length > 0 && (
                <tfoot>
                    {table.getFooterGroups().map(footerGroup => (
                        <Tr key={footerGroup.id}>
                            {footerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.footer,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </tfoot>
            )}
        </table>
    );
};
