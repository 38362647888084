import React, { FC, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { grayScale } from '../../constants/colors';
import { formatNumber } from '../../utils/numberFormatHelpers';
import { Legends } from './Legends';
import { Dataset, DatasetValue } from '../types/timeSeriesChart';

interface TimeseriesChartProps {
    datasets: Dataset[];
    labels: Date[];
    width?: number;
    height?: number;
    formatYValue?: (value: number) => string;
    linearGradientColor?: string;
    showLegends?: boolean;
}

export const TimeseriesChart: FC<TimeseriesChartProps> = ({
    datasets,
    labels,
    width = 800,
    height = 400,
    formatYValue,
    linearGradientColor,
    showLegends,
}) => {
    const svgRef = useRef();

    const margin = useMemo(() => ({ top: 20, right: 30, bottom: 30, left: 40 }), []);
    const adjustedWidth = width - margin.left - margin.right;
    const adjustedHeight = height - margin.top - margin.bottom;

    useEffect(() => {
        if (!datasets || datasets.length === 0) return;

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove(); // Clear SVG content before redrawing

        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Define scales
        const xScale = d3
            .scaleTime()
            .domain([
                d3.min(datasets, dataset => d3.min(dataset.values, d => moment(d.x).toDate())),
                d3.max(datasets, dataset => d3.max(dataset.values, d => moment(d.x).toDate())),
            ])
            .range([0, adjustedWidth]);

        const maxYValue = d3.max(datasets, dataset => d3.max(dataset.values, d => d.y));
        const yScale = d3.scaleLinear().domain([0, maxYValue]).range([adjustedHeight, 0]);

        // Calculate top and middle values for y-axis
        const middleYValue = maxYValue / 2;
        g.append('g')
            .call(
                d3
                    .axisLeft(yScale)
                    .tickValues([middleYValue, maxYValue])
                    .tickFormat((d, i) => {
                        const formattedValue = formatYValue ? formatYValue(d as number) : d;
                        return typeof formattedValue === 'string'
                            ? formattedValue
                            : String(formattedValue);
                    }),
            )
            .selectAll('.domain')
            .remove();

        g.append('g')
            .attr('transform', `translate(0,${adjustedHeight})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(labels)
                    .tickSizeOuter(0)
                    .tickFormat(d3.timeFormat('%b %d')),
            );

        // Styling
        g.select('.domain').attr('stroke', grayScale[60]); // Change axis line color
        g.selectAll('.tick text').attr('fill', grayScale[60]); // Change tick text color
        g.selectAll('.tick line').remove(); // Remove tick lines

        // Draw lines
        datasets.forEach(dataset => {
            const lineGenerator = d3
                .line<DatasetValue>()
                .x(d => xScale(moment(d.x).toDate()))
                .y(d => yScale(d.y))
                .curve(d3.curveMonotoneX); // This makes the line smooth

            g.append('path')
                .datum(dataset.values)
                .attr('fill', 'none')
                .attr('stroke', dataset.color)
                .attr('stroke-width', 1.5)
                .attr('d', lineGenerator)
                .attr('filter', dataset.useGlowEffect ? 'url(#glow)' : 'none'); // Apply glow to a specific line;

            if (dataset.useLinearGradient) {
                const areaGenerator = d3
                    .area<DatasetValue>()
                    .x(d => xScale(moment(d.x).toDate()))
                    .y0(adjustedHeight) // Bottom of the area (y-coordinate of x-axis)
                    .y1(d => yScale(d.y)) // Top of the area (y-coordinate of the data point)
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .datum(dataset.values)
                    .attr('fill', 'url(#area-gradient)') // Use the gradient fill for the area
                    .attr('d', areaGenerator);
            }
        });

        // Vertical line
        const verticalLine = g
            .append('line')
            .attr('class', 'verticalLine')
            .attr('y1', 0)
            .attr('y2', adjustedHeight)
            .style('stroke', grayScale[40])
            .style('display', 'none');

        // Dots group
        const dots = g
            .selectAll('.dots')
            .data(datasets)
            .enter()
            .append('circle')
            .attr('class', 'dot')
            .attr('r', 2)
            .style('fill', grayScale[100])
            .style('display', 'none');

        // Tooltip setup
        const tooltipGroup = g.append('g').attr('class', 'tooltip-group').style('display', 'none');
        // Tooltip background
        tooltipGroup
            .append('rect')
            .attr('width', 120) // Initial guess, adjust based on content
            .attr('height', 60) // Adjust based on content
            .attr('fill', 'none')
            .attr('rx', 4) // Rounded corners
            .attr('ry', 4);

        // Tooltip text will be added dynamically
        tooltipGroup
            .append('text')
            .attr('class', 'tooltip-text')
            .attr('x', 5) // A bit of padding from the rectangle's edge
            .attr('y', 20) // Adjust based on how you want to position the text inside the rectangle
            .attr('fill', grayScale[100]);

        function mousemove(event) {
            const [xPos] = d3.pointer(event, this); // Get the x position of the mouse within the chart
            const hoveredDate = xScale.invert(xPos); // Convert that x position back to a date

            // Find the closest date in the dataset to the hovered date
            let closestDate = null;
            let closestDistance = Infinity;

            // Go through each dataset to find the point closest to the hovered date
            datasets.forEach(dataset => {
                dataset.values.forEach(point => {
                    const pointDate = moment(point.x).toDate().getTime();
                    const distance = Math.abs(pointDate - hoveredDate.getTime());

                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestDate = pointDate;
                    }
                });
            });

            // If a closest date is found, display the vertical line and dots at this position
            if (closestDate) {
                const closestX = xScale(closestDate);

                // Update the vertical line to match the x position of the closest data point's date
                verticalLine.attr('x1', closestX).attr('x2', closestX).style('display', null);

                // Update positions of dots to match the y-values of the closest date across datasets
                dots.data(
                    datasets.map(dataset => {
                        const index = dataset.values.findIndex(
                            point => moment(point.x).toDate().getTime() === closestDate,
                        );
                        return index >= 0 ? dataset.values[index] : null;
                    }),
                )
                    .attr('cx', closestX)
                    .attr('cy', d => (d ? yScale(d.y) : 0))
                    .style('display', d => (d ? null : 'none')); // Only display the dot if the dataset has a value for this date

                // Tooltip start
                // Calculate tooltip positioning
                let tooltipX = closestX + 10; // Offset to the right of the line
                const tooltipY = -20; // Fixed top position
                let alignLeft = true; // Flag to indicate text alignment

                // Adjust tooltip position if it goes beyond the chart boundaries
                const tooltipWidth = 60; // Ensure this matches or dynamically calculate based on content
                if (tooltipX + tooltipWidth > adjustedWidth) {
                    tooltipX = closestX - tooltipWidth - 15; // Flip to the left side of the line
                    alignLeft = false; // Align text to the right
                }

                // Update tooltip position and make it visible
                tooltipGroup
                    .style('display', null)
                    .attr('transform', `translate(${tooltipX},${tooltipY})`);

                // Update tooltip content
                // Clear existing texts
                tooltipGroup.selectAll('.tooltip-text').remove();
                tooltipGroup.selectAll('.dataset-color-square').remove();

                // Header - Date
                tooltipGroup
                    .append('text')
                    .attr('class', 'tooltip-text')
                    .attr('x', alignLeft ? 5 : tooltipWidth - 5)
                    .attr('y', 25)
                    .attr('text-anchor', alignLeft ? 'start' : 'end') // Adjust text anchor based on alignment
                    .text(`${moment(closestDate).format('MMMM D')}`)
                    .style('font-size', '10px')
                    .attr('fill', grayScale[100]);

                // Display data for each dataset
                datasets.forEach((dataset, i) => {
                    const dataPoint = dataset.values.find(
                        point => moment(point.x).toDate().getTime() === closestDate,
                    );
                    if (dataPoint) {
                        // Colored square
                        tooltipGroup
                            .append('rect')
                            .attr('class', 'dataset-color-square')
                            .attr('x', alignLeft ? 5 : tooltipWidth - 10)
                            .attr('y', 33 + i * 15) // Position below the previous item
                            .attr('width', 5)
                            .attr('height', 5)
                            .attr('fill', dataset.color);

                        tooltipGroup
                            .append('text')
                            .attr('class', 'tooltip-text')
                            .attr('x', alignLeft ? 15 : tooltipWidth - 15)
                            .attr('y', 40 + i * 15) // Increment y position for each dataset
                            .attr('text-anchor', alignLeft ? 'start' : 'end')
                            .text(
                                `${
                                    dataPoint.y > 1000
                                        ? formatNumber(dataPoint.y, '$0,0')
                                        : formatNumber(dataPoint.y, '$0,0.00')
                                }`,
                            )
                            .style('font-size', '10px')
                            .attr('fill', grayScale[100]);
                    }
                });

                // Adjust the background rectangle size based on the content
                tooltipGroup.select('rect').attr('height', 40 + datasets.length * 20); // Adjust height based on the number of datasets displayed
                // Tooltip end
            } else {
                // Hide the vertical line and dots if no closest date is found
                verticalLine.style('display', 'none');
                dots.style('display', 'none');
                tooltipGroup.style('display', 'none'); // Hide tooltip if not close to any data point
            }
        }

        g.append('rect')
            .attr('class', 'overlay')
            .attr('width', adjustedWidth)
            .attr('height', adjustedHeight)
            .attr('opacity', 0)
            .attr('transform', `translate(0,0)`)
            .on('mouseover', () => {
                verticalLine.style('display', null);
                dots.style('display', null);
                tooltipGroup.style('display', null);
            })
            .on('mouseout', () => {
                verticalLine.style('display', 'none');
                dots.style('display', 'none');
                tooltipGroup.style('display', 'none');
            })
            .on('mousemove', mousemove);

        // Define glow filter start
        const defs = svg.append('defs');
        const filter = defs.append('filter').attr('id', 'glow');

        // The intensity and spread of the glow can be adjusted by changing the stdDeviation attribute in the feGaussianBlur element.
        filter
            .append('feGaussianBlur')
            .attr('stdDeviation', '4') // This value is the intensity of the glow
            .attr('result', 'coloredBlur');

        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode').attr('in', 'coloredBlur');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
        // Define glow filter end

        // Define the gradient for the area fill - start
        const gradientAreaDefs = svg.append('defs');
        const gradient = gradientAreaDefs
            .append('linearGradient')
            .attr('id', 'area-gradient') // Unique ID for the gradient
            .attr('gradientUnits', 'userSpaceOnUse') // Use the entire SVG for gradient coordinates
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '0%')
            .attr('y2', '90%');

        // Define the colors of the gradient from start to end
        gradient
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', linearGradientColor) // Color at the top
            .attr('stop-opacity', 0.1); // More opaque at the top

        gradient
            .append('stop')
            .attr('offset', '90%')
            .attr('stop-color', linearGradientColor) // Fade to white towards the bottom
            .attr('stop-opacity', 0); // Fully transparent at the bottom

        // Define the gradient for the area fill - end
    }, [
        datasets,
        width,
        height,
        labels,
        formatYValue,
        adjustedHeight,
        adjustedWidth,
        margin,
        linearGradientColor,
    ]);

    return (
        <div id="timeseries-chart-container">
            <svg ref={svgRef} width={width} height={height} />
            {showLegends && <Legends datasets={datasets} />}
        </div>
    );
};
