import { Modal } from '@mantine/core';
import { FC } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';
import ChatInterface from './ChatInterface';

interface FullPageChatProps {
    opened: boolean;
    onClose: () => void;
    brand: string;
    title?: string;
    suggestionCaretColor?: string;
    sendChat: UseMutateFunction<
        void,
        Error,
        {
            message: any;
            setResponse: any;
            setInProgress: any;
        },
        unknown
    >;
    chatLoading: boolean;
    suggestions?: string[];
}

export const FullPageChat: FC<FullPageChatProps> = ({
    opened,
    onClose,
    brand,
    suggestions,
    sendChat,
    chatLoading,
    title,
    suggestionCaretColor,
}) => (
    <Modal
        opened={opened}
        onClose={onClose}
        fullScreen
        className="absolute z-[9999]"
        transitionProps={{ transition: 'fade', duration: 500 }}
        classNames={{
            body: 'flex justify-center items-center w-full h-[90%]',
        }}
    >
        <div className="h-full max-w-[672px]">
            <ChatInterface
                mode="full-page"
                brand={brand}
                suggestions={suggestions}
                chatLoading={chatLoading}
                sendChat={sendChat}
                title={title}
                suggestionCaretColor={suggestionCaretColor}
            />
        </div>
    </Modal>
);
